@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;500;900&display=swap");

@layer base {
  body{
    margin: 0;
    padding: 0;
  }

  html {
    scroll-behavior: smooth;
  }

  @media only screen and (max-width: 425px){
    .leftLine {
      left: 100px;
      top:95px;
      color:#28256D
    }
    
    .rightLine {
      right: 100px;
      top:95px;
      color:#28256D
    }
  }
}

.header{
  color: #28256D;
  font-size: 15px;
}

.leftLine {
  left: 450px;
  top:95px;
  color:#28256D
}

.rightLine {
  right: 450px;
  top:95px;
  color:#28256D
}

.leftBottomLine {
  left: 515px;
  bottom:-124px;
  color:#28256D
}

.rightBottomLine {
  right: 515px;
  bottom:-124px;
  color:#28256D
}

.leftServiceLine {
  left: 188px; 
  top:-26px;
  color:#28256D
}

.rightServiceLine {
  right: 188px;  
  top:-26px;
  color:#28256D
}

.leftTestinomialLine {
  left: 188px; 
  top:-20px;
  color:#28256D
}

.rightTestinomialLine {
  right: 188px;  
  top:-20px;
  color:#28256D
}

.footerLine {
  left: 100px; 
  top:-26px;
  color:#28256D
}

[x-cloak] { display: none; }

h6{
  color:white;
}




